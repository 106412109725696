<template>
  <div class="app-container">
    <el-card shadow="never">
      <div class="-mb-16px">
        <el-form
          ref="searchRefs"
          :model="params"
          size="small"
          label-width="70px"
          inline
        >
          <el-form-item label="站点名称" prop="name">
            <el-input
              v-model="params.name"
              placeholder="请输入站点名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="站点网址" prop="url">
            <el-input
              v-model="params.url"
              placeholder="请输入站点网址"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户邮箱" prop="email">
            <el-input
              v-model="params.email"
              placeholder="请输入客户邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="发送状态" prop="status">
            <el-select v-model="params.status" placeholder="请选择发送状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="转发成功" :value="2"></el-option>
              <el-option label="等待转发" :value="1"></el-option>
              <el-option label="转发失败" :value="0"></el-option>
              <el-option label="转发屏蔽" :value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml-6px">
            <el-button type="primary" @click="getList(1)">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="mt-16px" v-loading="pager.loading" shadow="never">
      <div></div>
      <div class="mt-16px">
        <el-table :data="pager.list">
          <el-table-column
            prop="id"
            label="线索ID"
            width="80"
          ></el-table-column>
          <el-table-column label="站点名称" min-width="100" :show-overflow-tooltip="true">
            <template v-slot="scope">
              <span> {{ scope.row.name }} </span>
            </template>
          </el-table-column>
          <el-table-column label="站点网址" width="260">
            <template v-slot="scope">
              <span> {{ scope.row.url }} </span>
            </template>
          </el-table-column>
          <el-table-column label="客户邮箱" width="200">
            <template v-slot="scope">
              <span> {{ scope.row.email }} </span>
            </template>
          </el-table-column>
          <el-table-column
            label="线索内容"
            min-width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <div>
                <div>{{ scope.row.content }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="160">
            <template v-slot="scope">
              <span>
                {{ formatDate(scope.row.create_time, "yyyy-mm-dd hh:MM:ss") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="发送状态" width="100">
            <template v-slot="scope">
              <el-tag size="small" type="success" v-if="scope.row.status === 2">转发成功</el-tag>
              <el-tag size="small" type="info" v-if="scope.row.status === 1">等待转发</el-tag>
              <el-tag size="small" type="danger" v-if="scope.row.status === 0">转发失败</el-tag>
              <el-tag size="small" type="danger" v-if="scope.row.status === 5">转发屏蔽</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="发送时间" width="160">
            <template v-slot="scope">
              <span v-if="scope.row.send_time">
                {{ formatDate(scope.row.send_time, "yyyy-mm-dd hh:MM:ss") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template v-slot="scope">
              <div class="flex items-center">
                <el-button type="text" @click="onDetail(scope.row.id)">
                  查看线索
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <popup
                  :content="`确定删除编号为 ${scope.row.id} 的线索？请谨慎操作。`"
                  @confirm="onDelete(scope.row)"
                >
                  <el-button slot="trigger" type="text">删除</el-button>
                </popup>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-between mt-16px">
        <div class="flex"></div>
        <pagination
          v-model="pager"
          :pageSizes="[10, 20, 30]"
          @change="getList()"
        ></pagination>
      </div>
    </el-card>

    <edit-dialog ref="popupRefs" @refresh="getList"></edit-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import Popup from "@/components/popup";
import {Pager} from "@/utils/pager";

import {apiListClue, apiDeleteClue} from "@/api/clue";

import EditDialog from "./dialog.vue";
import {formatDate} from "@/utils/util";

export default {
  components: {
    EditDialog,
    Pagination,
    Popup,
  },
  data() {
    return {
      params: {
        name: "",
        url: "",
        email: "",
        status: "",
      },
      pager: new Pager(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    formatDate,
    onReset() {
      this.$refs.searchRefs.resetFields();
      this.getList(1);
    },
    getList(page) {
      page && (this.pager.page = page);
      this.pager.request({
        callback: apiListClue,
        params: {
          ...this.params,
        },
      });
    },
    onDetail(id) {
      this.$refs.popupRefs.openDialog(id);
    },
    onDelete(row) {
      apiDeleteClue({
        id: row.id,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        this.getList();
      });
    },
  },
};
</script>
