import request from "@/utils/request";

export function apiListClue(params) {
  return request.get("/clue/list", params);
}

export function apiDetailClue(params) {
  return request.get("/clue/detail", params);
}

export function apiEditClue(params) {
  return request.post("/clue/edit", params);
}

export function apiDeleteClue(params) {
  return request.post("/clue/delete", params);
}
