<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="700px"
    title="查看线索"
    :confirm-button="false"
    cancel-button="关闭"
    @close="onClose"
  >
    <div>
      <div v-for="(value, key) in form.content" :key="key" style="margin-bottom: 10px;">
        <strong>{{ key }}：</strong> {{ value }}
      </div>
    </div>

  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";

import {apiDetailClue} from "@/api/clue";

export default {
  components: {
    Popup,
  },
  data() {
    return {
      identity: null,
      loading: false,
      form: {
        content: {},
      },
    };
  },
  computed: {},
  methods: {
    getDetail() {
      this.loading = true;
      apiDetailClue({id: this.identity}).then((resp) => {
        Object.keys(resp).map((item) => {
          this.$set(this.form, item, resp[item]);
          this.loading = false;
        });
      });
    },
    openDialog(id) {
      this.identity = id;
      if (this.identity) {
        this.getDetail();
      }
      this.$refs.popupRefs.open();
    },
    onClose() {
      this.$nextTick(() => {
      });
    }
  },
};
</script>
